/* eslint-disable @typescript-eslint/no-empty-function */
import { LastLocationType } from 'react-router-last-location';
import { AuthProvider } from '@marvel-common/ridehailing-idp';
import ReactGA from 'react-ga4';
import { History } from 'history';

import { staticConfig } from '../config';

export interface GAClientUserTraits {
  slug?: string;
  partner: string;
  partnerRegion: string;
  authProvider: AuthProvider;
}

class GA4Client {
  _history?: History;

  public constructor(history?: History) {
    this._history = history;

    ReactGA.initialize(staticConfig.analytics.ga4MeasurementId, { testMode: true });
  }

  reset(): void {
    ReactGA.reset();
  }

  async identify(traits?: GAClientUserTraits): Promise<void> {
    if (!traits) {
      return;
    }
    const fields = {
      marvel_slug: traits?.slug?.length ? traits.slug : 'Direct',
      marvel_partner: traits.partner,
      marvel_partner_region: traits.partnerRegion,
      marvel_auth_provider: traits.authProvider,
    };
    ReactGA.send(fields);
  }

  async page(_lastLocation: LastLocationType | null, _name: string, _properties?: any): Promise<void> {
    // Page views (based on historyChange event) are tracked automatically in GA4.
    // Do not send, unless the automatic page view tracking is disabled.
    /*
    ReactGA.send({
      hitType: 'pageview',
      page: _lastLocation?.pathname ?? this._history?.location.pathname,
      title: name,
    });
    */
  }

  async track(_lastLocation: LastLocationType | null, event: string, ga4Traits?: any): Promise<void> {
    ReactGA.event(event, ga4Traits);
  }
}

export default GA4Client;
